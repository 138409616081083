
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Subscriptions`}</h1>
    <p>{`A running `}<strong parentName="p">{`subscription`}</strong>{` means someone registered and is paying for a certain
service within the c.technology cloud ecosystem. In its most simple form, people
purchase / renew subscriptions for individual vehicles, in order to activate their SIM
cards, the processing, and enable the continued development of functionalities.`}</p>
    <p>{`However, subscriptions are also available for services, thus it becomes possible
for you as a service / app developer to create your own applications, let
people / vehicles in the c.technology ecosystem register for them, and thus
generate revenue for yourself.`}</p>
    <p>{`The c.technology cloud system automatically checks (after all permission checks)
if a vehicle / service, or combination thereof, has a valid subscription and
thus lets you develop your applications completely worry-free!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;