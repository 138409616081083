
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Integrations`}</h1>
    <p>{`In this section, we talk about common integration scenarios, such as
Azure or AWS IoT, digital twin creation and management tools, edge AI
& processing, Android for cars, or high-throughput streaming (e.g., for
camera feeds).`}</p>
    <p><strong parentName="p">{`Please note`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`These are only guidelines on how you could set up such an IoT
system - concrete usecases should always be discussed with us. Drop us
a message via `}<a parentName="li" {...{
          "href": "https://ctechnology.io/contact"
        }}>{`ctechnology.io/contact`}</a>{`
and we'll happily get back to you to discuss your specific needs.`}</li>
      <li parentName="ul">{`If you are not a vehicle manufacturer or only produce a smaller number
of vehicles per year, it is likely too much effort to build your own
complete IoT infrastructure. In this case, you can use our pre-built and
out-of-the box solutions for boats (clickahoy), motorcycles (clickrider),
and electric vehicles (clickev). Again, drop us a message via
`}<a parentName="li" {...{
          "href": "https://ctechnology.io/contact"
        }}>{`ctechnology.io/contact`}</a>{` and we'll gladly
help you out!`}</li>
    </ul>
    <h2>{`IoT provider and management tool`}</h2>
    <p>{`Many of the large cloud providers offer functionalities for IoT device
management and digital twin creation. These are vehicle- / mobility-agnostic
and thus do not consider many of the special circumstances and needs a
vehicle is commonly in or has.`}</p>
    <p>{`The c.technology cloud is built to provide this missing yet mission-
critical functionality by letting you connect your IoT provider cloud
directly to the c.technology cloud and immediately profiting from the
automatic vehicle data processing as well as related services.`}</p>
    <h2>{`Smart vehicle dashboard`}</h2>
    <p>{`Similar to how you might use an IoT provider / management tool to administer
your IoT devices, you might consider integrating a smart dashboard such as
Android for cars into your vehicles. The integration with the c.technology cloud
works similar in that case, simply connect your smart dashboard to the c.technology
backend, and immediately profit from all the additional services we provide
specifically for powersports vehicles.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;