
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Apps / services`}</h1>
    <p>{`Apps resp. `}<strong parentName="p">{`services`}</strong>{` (within the c.technology system those refer to the same)
form the primary extension point of the c.technology cloud. A service can either
belong to an individual user (anyone can create apps!) or an organization and
share the different available permissions (in addition to having some permissions
unique to services).`}</p>
    <p>{`Upon creating an app in the developer webapp interface, you receive a unique
identifier, as well as a public / private key pair, and you can create an
arbitrary number of authentication tokens (either directly from the webapp
or by using the respective API) which you can use to perform actions
on behalf of this service.`}</p>
    <p>{`How it works after you received your token is exactly the same as if you
would act on behalf of a user or organization: The c.technology cloud checks
your permissions and access rights, and performs actions on behalf of
the respective service. However, unlike users or organizations, services
have some pre-build structures read for your use, can be used to register
webhooks, have some additional permissions, come with cryptographically
secure keys (that can be used within different apps outside of the
c.technology cloud ecosystem), and offer the possibility to directly
integrate into the c.technology cloud (incl. payments, subscriptions, etc.).`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;