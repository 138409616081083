
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Vehicles`}</h1>
    <p><strong parentName="p">{`Vehicles`}</strong>{` are at the core of the c.technology cloud. They all have a `}<strong parentName="p">{`general
profile`}</strong>{` (where the brand, vehicle type, owner-given name, license plate, etc.
can be stored), and a `}<strong parentName="p">{`vehicle status`}</strong>{` (where the vehicle currently is, what
sensor readings and potential error codes it shows, etc.), next to countless
other functionalities.`}</p>
    <p>{`You can think of a vehicle on the c.technology cloud as a proper and fully-
functional `}<strong parentName="p">{`digital twin`}</strong>{`: Not only can you connect a wide range of different
sensors and actuators, we automatically use the sensor readings to predict
future states and behaviors, potential failure states, energy consumptions
and range estimations, and so on. Additionally, our vehicle digital twins
get unique and secure identification capabilities, paving the way for letting
them automatically interact with a wide range of compatible devices, and
even automatically book maintenance services or order replacement parts.`}</p>
    <p>{`Of course, we have many more "basic" functionalities on offer: checklists,
shared calendars, predictive and preventive maintenance, automatic usage
analysis, trip extraction, augmentation, and sharing, social interactions,
interaction with your maintenance partner, remote assistance, vehicle management,
service logbooks, notifications and alerts, and many more.`}</p>
    <h2>{`Permissions`}</h2>
    <p>{`All users and organiziations have fine-grained access to individual vehicles
using our `}<strong parentName="p">{`permissions`}</strong>{` system. For example, a vehicle owner might see everything
that happens with a vehicle, while a friend's account can only see its
availability in a calendar. Or an OEM can only access sets of vehicles in
an aggregated way (and never see where the vehicles actually move).`}</p>
    <p>{`You can find the complete set of permissions under the respective endpoint
documentation.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;