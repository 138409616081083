
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import StaticCodeSnippet from '../../components/StaticCodeSnippet';
import codeSnippet from '!!raw-loader!./discordintegration.snippet';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Discord integration`}</h1>
    <p>{`The following code snippet shows you how to relay messages to Discord using the `}<a parentName="p" {...{
        "href": "https://pypi.org/project/discord.py"
      }}>{`Discord Python library`}</a>{`.
This is an easy way to build your own customized push messages regarding your vehicle(s) (of course you can also filter and relay notifications and alerts generated by the c.technology real-time engine).
Simply set up a Discord account, `}<a parentName="p" {...{
        "href": "https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
      }}>{`get a Webhook`}</a>{`, and paste the URL into the prompt when running the Python script below.
In the `}<inlineCode parentName="p">{`on_message`}</inlineCode>{` function, you can filter out messages, reformat them, etc.
Simply make sure you have push notifications enabled for your Discord server, you have a place where you can let the script keep running, and you're ready to go!`}</p>
    <StaticCodeSnippet language="python" code={codeSnippet} mdxType="StaticCodeSnippet"></StaticCodeSnippet>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;