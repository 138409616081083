
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Smart home integration`}</h1>
    <p style={{
      fontWeight: 'bold'
    }}>
  🚧🚨🚧🚨🚧 
  <br />Coming soon!
  <br />🚧🚨🚧🚨🚧
    </p>
    <p>{`The following code snippets show how you can use the c.technology API to get smart home notifications when something happens to your vehicle (e.g., for Google Nest or Amazon Alexa).
You could for example set alerts in case someone moves the vehicle, or have functionality to ask your smart home provider about the fuel and battery levels of your vehicle.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;