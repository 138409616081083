
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Dealer management system integration`}</h1>
    <p style={{
      fontWeight: 'bold'
    }}>
  🚧🚨🚧🚨🚧 
  <br />Coming soon!
  <br />🚧🚨🚧🚨🚧
    </p>
    <p>{`Dealerships of the motorcycle company "MC Zurich" commonly already use the dealer management system (DMS) "DMS 9000".
Commonly, they only have access to the motorcycle data when their clients bring their motorcycles to the shop for repairs.`}</p>
    <p>{`By using the c.technology system, data is continuously collected from all vehicles sold and handled by "MC Zurich".
While the c.technology (and clickrider) dashboards give access to the vehicle data at various levels, it would further facilitate efficient aftersales if the vehicle data could additionally be accessed from within the "DMS 9000".
Using the c.technology API, creating this connection is straightforward and can be updated periodically or in real-time.`}</p>
    <p>{`The following snippet shows how "MC Zurich" retrieves the most up-to-date information from c.technology once per day, and stores it into their "DMS 9000" database.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;