
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import StaticCodeSnippet from '../../components/StaticCodeSnippet';
import codeSnippet1 from '!!raw-loader!./vehiclestatuslistener_1.snippet';
import codeSnippet2 from '!!raw-loader!./vehiclestatuslistener_2.snippet';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`React status app`}</h1>
    <p>{`If you want to build your own web or native app, React is a popular choice nowadays.
The following JSX snippet shows how to set up a very basic application that listens for vehicle updates on the WebSocket.`}</p>
    <StaticCodeSnippet language="jsx" code={codeSnippet1} mdxType="StaticCodeSnippet"></StaticCodeSnippet>
    <p>{`Usually, we would want to load some vehicle profile and status data upon an initial app load.
The following Typescript snippet shows a more complete application that first loads data, and then updates it based on incoming WebSocket messages.`}</p>
    <StaticCodeSnippet language="tsx" code={codeSnippet2} mdxType="StaticCodeSnippet"></StaticCodeSnippet>
    <p>{`For both snippets, fill in your email address and password, and note that there is no error handling and no distinction between different vehicles.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;