
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Vehicle sharing`}</h1>
    <p style={{
      fontWeight: 'bold'
    }}>
  🚧🚨🚧🚨🚧 
  <br />Coming soon!
  <br />🚧🚨🚧🚨🚧
    </p>
    <p>{`The boat company "Unlimited Boating" provides a interaction-free boat sharing (i.e., people unlock the boats simply using an app, and no employee is on site).
To make sure people do not drive into forbidden zones, or get into troubles because of lack of fuel to drive back, c.technology services are used to control the boat motor and inform people in case of dangerous situations.`}</p>
    <p>{`"Unlimited Boating" created the following plugin for the c.technology cloud system that continuously listens to notifications generated by the vehicles, and informs their customers by showing a red "alert bubble" in their sharing app as well as on the vehicle itself (the latter is again controlled via the c.technology cloud).`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;