
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Code examples`}</h1>
    <p>{`We created some exemplary applications that may serve as a starting point to develop your own applications using the c.technology cloud services via our APIs.
The following code samples are available:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Custom monitoring`}</strong>{`: Example of creating a custom Qt dashboard that displays real-time information provided by c.technology.`}</li>
      <li parentName="ul"><strong parentName="li">{`Discord integration`}</strong>{`: Example of a Discord (comparable to Slack) integration that posts vehicle alerts and notifications.`}</li>
      <li parentName="ul"><strong parentName="li">{`React status listener`}</strong>{`: How to set up a React (native or web) app to listen for vehicle updates from the c.technology API.`}</li>
      <li parentName="ul"><strong parentName="li">{`Vehicle sharing`}</strong>{`: How to set up a deep integration of a shared vehicle system (with alerts and LED indication lights).`}</li>
      <li parentName="ul"><strong parentName="li">{`Dealer Management System (DMS) integration`}</strong>{`: Exemplary integration of c.technology systems and a Dealer Management System.`}</li>
      <li parentName="ul"><strong parentName="li">{`Smart home integration`}</strong>{`: How to set up c.technology services with Google Nest or Amazon Alexa.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;