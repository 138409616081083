
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Webhooks`}</h1>
    <p><strong parentName="p">{`NOTE: Currently, webhooks are only available to a selected set of Beta API
users. Please let us know if you would like to have access to them!`}</strong></p>
    <p>{`Webhooks are a convenient and easy-to-setup way for real-time / event-based
communication. In essence, you let the c.technology cloud system know to which
HTTPS endpoint it should send a message if something of interest happens.`}</p>
    <p>{`For example, when a vehicle moves (updates the vehicle status), we could send
this new status as a POST request to your endpoint `}<inlineCode parentName="p">{`https://example.com/vehicle-moving`}</inlineCode>{`.
The data being sent is the same as if you would listen to a WebSocket update
or query the corresponding REST endpoint.`}</p>
    <h2>{`Setup`}</h2>
    <p>{`You can register webhooks for any app / service that you created in the developer
section of our webapp. Simply use the web interface and set up the webhooks you need.`}</p>
    <p>{`Alternatively, you can set them up directly using the REST API. Please consider the
documentation of the respective endpoint.`}</p>
    <h2>{`Security`}</h2>
    <p>{`As for now, requests sent to your specified endpoint(s) are signed using your apps
public key. You should use the private key that was created during the setup process
of your app in order to verify the request actually came from the c.technology backend.`}</p>
    <h2>{`Acknowledgements and retries`}</h2>
    <p>{`We expect a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` answer to all requests sent to a webhook endpoint. This response
mus arrive within 3 seconds of sending the request, i.e., you should not do any heavy
processing during creation of your response, but process it quickly and acknowledge it.`}</p>
    <p>{`The c.technology backend will retry failed webhook requests 3 times over the duration of
15 minutes, and log the failed attempts to your developer dashboard for development and
debugging purposes.`}</p>
    <p>{`It might thus happen that data does not arrive at your endpoint in an ordered manner
(e.g., a vehicle status request that failed might be sent 15 minutes late, at which
point already other vehicle statuses were sent to your backend). It is thus important
that you consider the `}<inlineCode parentName="p">{`timestamp`}</inlineCode>{` fields of data sent to your backend, and order things
accordingly!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;