
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`c.technology cloud and API documentation`}</h1>
    <p>{`Welcome to the c.technology cloud and API documentation. The c.technology cloud infrastructure
provides everything you could possibly need to connect your (powersports) vehicles to the cloud
and provide your customers and partners with an out-of-the-box solution that is easily and
limitlessly extensible thanks to our powerful and streamlined APIs.`}</p>
    <p>{`Our cloud is built with reliability, limitless scale, a technology-centric stance, agile and
fast development, and real-time processing and updating at its core. For you, this means that
you can either use a request-response approach (our REST API) or a push-based approach (our
RT API), or any combination of the two.`}</p>
    <p>{`This documentation is split along this line, but (and as you will see), all the data structures
and most usage patterns are shared between the two methods, both follow best practices for API
definitions, and we provide not only auto-derived client libraries but enhance them with our
own ideas of how things should work, in order to give you tools that get you started within
minutes.`}</p>
    <p>{`Our documentation is split into several parts:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Code examples`}</strong>{`, where you can find starting points and interesting usecases about how
to use the c.technology APIs.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`REST API`}</strong>{`, which describes the request-response part of our APIs.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`RT API`}</strong>{` (real-time API), which describes the "dynamic" event- / push-based parts of our APIs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Manuals`}</strong>{`, which focus on the hardware installation and are PDF documents that can easily be
printed out and taken along during the installation process.`}</li>
      <li parentName="ul">{`Frequently asked questions (`}<strong parentName="li">{`FAQ`}</strong>{`), which summarize customer feedback, inputs, and questions
over the years into concise answers.`}</li>
    </ul>
    <p>{`Use the navigation bar above to access the different parts.`}</p>
    <h2>{`The c.technology cloud`}</h2>
    <p>{`On the left, you can find sections that talk in more detail about different aspects of the c.technology
cloud. On a very high level, we differentiate between `}<strong parentName="p">{`users`}</strong>{` (individual people), `}<strong parentName="p">{`organizations`}</strong>{`
(collections of users that are related to collections of vehicles - e.g., a motorcycle dealer, a boat
rental, a shipyard, etc.), apps resp. `}<strong parentName="p">{`services`}</strong>{` (that are used for automations, additional tools and
extensions that rely on or interact with the c.technology infrastructure, and so on), and `}<strong parentName="p">{`vehicles`}</strong>{`
(which are preferrably outfitted with an IoT device - but they do not necessarily have to be).`}</p>
    <p>{`This flexible architecture lets multiple (and completely different) stakeholders access the same
vehicles with different permissions, anonymization levels, user interfaces, etc., and thus allows
everyone to directly communicate, profit from the same underlying data, use a wealth of additional
apps and services, and so on.`}</p>
    <p>{`Finally, the sections on `}<strong parentName="p">{`subscriptions`}</strong>{` and `}<strong parentName="p">{`integrations`}</strong>{` talk about how individual services can be
paid for (thus allowing things like upselling, aftersales, revenue sharing, etc.), and common
scenarios on how to connect the c.technology cloud to other providers like Azure or AWS IoT,
digital twin creation and management tools, edge AI & processing, Android for cars, high-throughput
streaming (e.g., for camera feeds), etc.`}</p>
    <h2>{`Additional notes`}</h2>
    <p>{`Our software-focused specifications are currently only available in English.
The hardware installation manuals can be downloaded in English and German.`}</p>
    <p>{`The c.technology REST API is specified using `}<a parentName="p" {...{
        "href": "https://www.openapis.org"
      }}>{`OpenAPI`}</a>{`.
You can download the full specification `}<a parentName="p" {...{
        "href": "https://docs.ctechnology.io/ctechnology-openapi.yml"
      }}>{`by clicking here`}</a>{`.
This can be useful if you want to automatically generate a client library for example.`}</p>
    <p>{`The c.technology RT API is specified using `}<a parentName="p" {...{
        "href": "https://www.asyncapi.com"
      }}>{`AsyncAPI`}</a>{`.
Similarly, you can download the full specification `}<a parentName="p" {...{
        "href": "https://docs.ctechnology.io/ctechnology-asyncapi.yml"
      }}>{`by clicking here`}</a>{`,
to generate client libraries, etc.`}</p>
    <p>{`Visit our `}<a parentName="p" {...{
        "href": "https://github.com/c-technology"
      }}>{`Github organization`}</a>{` to take a look at our client and
open source libraries. We also work together closely with the ETH Zurich
`}<a parentName="p" {...{
        "href": "https://github.com/mie-lab"
      }}>{`Mobility Information Engineering`}</a>{` research lab, where you can find more interesting
tools and libraries for mobility and movement data.`}</p>
    <h2>{`Stuck somewhere?`}</h2>
    <p>{`If you have any questions, you can always reach us via `}<a parentName="p" {...{
        "href": "https://ctechnology.io/contact"
      }}>{`ctechnology.io/contact`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;