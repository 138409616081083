/* eslint-disable import/no-webpack-loader-syntax */
import DiscordIntegration from '!babel-loader!@mdx-js/loader!./code-samples/discordintegration.mdx';
import DMSIntegration from '!babel-loader!@mdx-js/loader!./code-samples/dms.mdx';
import CodeSamples from '!babel-loader!@mdx-js/loader!./code-samples/index.mdx';
import QtCustomMonitoring from '!babel-loader!@mdx-js/loader!./code-samples/monitoring.mdx';
import VehicleSharing from '!babel-loader!@mdx-js/loader!./code-samples/sharing.mdx';
import SmartHomeIntegration from '!babel-loader!@mdx-js/loader!./code-samples/smarthomeintegration.mdx';
import ReactStatusApp from '!babel-loader!@mdx-js/loader!./code-samples/vehiclestatuslistener.mdx';
import AppsServices from '!babel-loader!@mdx-js/loader!./introduction/appsservices.mdx';
import Introduction from '!babel-loader!@mdx-js/loader!./introduction/index.mdx';
import Integrations from '!babel-loader!@mdx-js/loader!./introduction/integrations.mdx';
import Subscriptions from '!babel-loader!@mdx-js/loader!./introduction/subscriptions.mdx';
import UsersOrganizations from '!babel-loader!@mdx-js/loader!./introduction/usersorganizations.mdx';
import Vehicles from '!babel-loader!@mdx-js/loader!./introduction/vehicles.mdx';

export const StaticScreens = {
  introduction: {
    IntroductionScreen: {
      name: 'Introduction',
      construct: Introduction,
    },
    UsersOrganizationsScreen: {
      name: 'Users and organizations',
      construct: UsersOrganizations,
    },
    VehiclesScreen: {
      name: 'Vehicles',
      construct: Vehicles,
    },
    AppsServicesScreen: {
      name: 'Apps / services',
      construct: AppsServices,
    },
    SubscriptionsScreen: {
      name: 'Subscriptions',
      construct: Subscriptions,
    },
    IntegrationsScreen: {
      name: 'Integrations',
      construct: Integrations,
    },
  },
  codeSamples: {
    CodeSamplesScreen: {
      name: 'Code examples',
      construct: CodeSamples,
    },
    QtCustomMonitoringScreen: {
      name: 'Qt custom monitoring',
      construct: QtCustomMonitoring,
    },
    DiscordIntegrationScreen: {
      name: 'Discord integration',
      construct: DiscordIntegration,
    },
    ReactStatusAppScreen: {
      name: 'React status app',
      construct: ReactStatusApp,
    },
    VehicleSharingScreen: {
      name: 'Vehicle sharing',
      construct: VehicleSharing,
    },
    DMSIntegrationScreen: {
      name: 'DMS integration',
      construct: DMSIntegration,
    },
    SmartHomeIntegrationScreen: {
      name: 'Smart home integration',
      construct: SmartHomeIntegration,
    },
  },
};
