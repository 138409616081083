import '@stoplight/elements/styles.min.css';
import React, { ReactFragment } from 'react';
import styledComponents from 'styled-components';
import './ContentArea.scss';

const StyledContentArea = styledComponents.div`
height: 100%;
display: flex;
border: 0 solid #cbd5e0;
`;

const StyledSidebar = styledComponents.div`
width: calc((100% - 1800px) / 2 + 300px);
padding-left: calc((100% - 1800px) / 2);
padding-top: 5rem;
min-width: 300px;
position: sticky;
background-color: #ebeef5;
border-right-width: 1px;
`;

const StyledSidebarItem = styledComponents.div`
padding: 0.4rem;
padding-left: 48px;
opacity: 0.6;
cursor: pointer;
&:hover {
  opacity: 1;
  background-color: #c7d4f1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
`;

const StyledContent = styledComponents.div`
width: 100%;
padding-left: 96px;
padding-right: 96px;
padding-top: 64px;
padding-bottom: 64px;
overflow-x: hidden;
overflow-y: auto;
flex: 1 1;
`;

export type DocsScreen = {
  name: string;
  construct: (props: any) => JSX.Element;
};

function Sidebar(props: { children: React.ReactFragment }) {
  return <StyledSidebar>{props.children}</StyledSidebar>;
}

function Content(props: { children: React.ReactFragment; style?: React.CSSProperties }) {
  return <StyledContent style={props.style ? props.style : {}}>{props.children}</StyledContent>;
}

export default function ContentArea(props: {
  children?: React.ReactFragment;
  screens: DocsScreen[];
  contentStyle?: React.CSSProperties;
}) {
  const [screen, setScreen] = React.useState('');

  const sideBarContent = props.screens.map((screen) => {
    return <StyledSidebarItem onClick={() => setScreen(screen.name)}>{screen.name}</StyledSidebarItem>;
  });

  let content: ReactFragment = <React.Fragment></React.Fragment>;
  if (props.children) {
    // If we have a child component, we always use that.
    content = props.children;
  } else {
    // If we have screens, we either use the one specified in the state, or
    // the first one.
    if (props.screens.length > 0) {
      const selectedScreen = props.screens.filter((s) => s.name === screen);
      if (selectedScreen.length === 1) {
        content = selectedScreen[0].construct({});
      } else {
        content = props.screens[0].construct({});
      }
    }
  }

  return (
    <StyledContentArea className="content-area">
      <Sidebar>{sideBarContent}</Sidebar>
      <Content style={props.contentStyle}>{content}</Content>
    </StyledContentArea>
  );
}
