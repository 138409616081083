
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import StaticCodeSnippet from '../../components/StaticCodeSnippet';
import codeSnippet from '!!raw-loader!./monitoring.snippet';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Custom monitoring using Qt`}</h1>
    <p>{`In their boathouse, the marine rescue service "MRS Zurich" already has a monitoring dashboard where incoming requests and live feeds from weather services and webcams are shown.
To further improve the usefulness of their dashboard, they want to show real-time data from their ships, including if they are connected to the 230V landline, their fuel level, and potentially occurring alerts.`}</p>
    <p>{`The (hypothetical) monitoring dashboard is a `}<a parentName="p" {...{
        "href": "https://www.qt.io/qt-for-python"
      }}>{`Qt for Python`}</a>{` application that aggregates information from different sources.`}</p>
    <p>{`The connection to the c.technology real-time engine is implemented as follows:`}</p>
    <StaticCodeSnippet language="python" code={codeSnippet} mdxType="StaticCodeSnippet"></StaticCodeSnippet>
    <p>{`For exemplary purposes the account information is retrieved via the command line in this example.
Note that logging out a user explicitly is not strictly required, but recommended to remove old tokens.`}</p>
    <p>{`Additionally, you would probably retrieve an initial vehicle status using the REST API, and somehow separate the incoming WebSocket message according to the vehicle ID (right now, simply the latest incoming vehicle data is shown).
Note that the app crashes in case anything else than a vehicle status update is sent via the WebSocket; this should be handled accordingly as well.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;